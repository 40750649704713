@import '../../styles/index.scss';

@keyframes logoAnimation {
  0% {
    opacity: 0;
    border-width: 0;
  }
  100% {
    opacity: 1;
    border-width: 30px;
  }
}

.logoCircle {
  border: 0px solid $primary;
  border-radius: 100%;
  height: 120px;
  width: 120px;
  box-sizing: border-box;
  animation: logoAnimation 0.5s ease-in-out forwards;
  margin-bottom: 1em;
  margin-top: 25vh;

  transition: top 0.5s ease-in-out, left 0.5s ease-in-out, border-color 0.3s ease-in-out;

  @include media-breakpoint-down(lg) {
    height: 110px;
    width: 110px;
  }

  &.show {
    border-color: $primary !important;
  }

  &.hide {
    border-color: transparent !important;
  }
}

.aboutUs {
  opacity: 1;

  .header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading {
    color: $txt;
    font-weight: bold;
    font-size: 35px;
    // padding: 0 20%;
    text-align: center;
    line-height: 1.3em;
    white-space: pre-wrap;
    margin-bottom: 15px !important;
  }

  & > .title {
    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);

    color: $txt;
    font-size: 40px;
    font-family: Nexa;
    margin-bottom: 50px;
    font-weight: normal;
    text-align: center;
    transition: opacity 0.5s ease, transform 0.5s ease, moz-opacity 0.5s ease,
      -moz-transform 0.5s ease, -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  .text {
    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);
    color: $txt;
    font-size: 16px;
    max-width: 950px;
    text-align: center;
    line-height: 24px;

    transition: opacity 0.5s ease, transform 0.5s ease, moz-opacity 0.5s ease,
      -moz-transform 0.5s ease, -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease;
    transition-delay: 0.3s;

    margin: auto;
    white-space: pre-wrap;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 4em;
    }

    &.sAnimated {
      font-size: 1.33em;
      line-height: 1.5em;
      margin-top: 10px;

      @include media-breakpoint-down(md) {
        line-height: 1.25em;
      }
    }
  }

  .counters {
    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 50px;

    transition: opacity 0.5s ease, transform 0.5s ease, moz-opacity 0.5s ease,
      -moz-transform 0.5s ease, -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease;
    &.text:not(.sAnimated) {
      transition-delay: 0.8s;
    }
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      width: 380px;
      max-width: 90vw;
      margin-left: auto;
      margin-right: auto;
    }

    .counter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      width: 190px;

      @include media-breakpoint-down(sm) {
        width: calc(50% - 10px);
        margin: 20px 5px;
        box-sizing: border-box;
      }

      @include media-breakpoint-down(md) {
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 25px;
        }
      }

      .title {
        color: $txt;
        text-align: center;
        font-size: 60px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        font-family: NexaBold;

        @include media-breakpoint-down(md) {
          font-size: 45px;
        }
      }
      .subtitle {
        color: $txt;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        margin: 0 auto;
      }
    }
  }

  .animated {
    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);
    transition-delay: 1.3s;
    transition: opacity 0.5s ease 0.9s, transform 0.5s ease 0.9s, moz-opacity 0.5s ease 0.9s,
      -moz-transform 0.5s ease 0.9s, -webkit-opacity 0.5s ease 0.9s,
      -webkit-transform 0.5s ease 0.9s;
  }

  .button {
    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);
    transition-delay: 1.3s;
    margin: auto;
    margin-top: 15px;

    transition: border-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s, opacity 0.5s ease 0.9s,
      transform 0.5s ease 0.9s, moz-opacity 0.5s ease 0.9s, -moz-transform 0.5s ease 0.9s,
      -webkit-opacity 0.5s ease 0.9s, -webkit-transform 0.5s ease 0.9s,
      background-color 0.3s ease-in-out, box-shadow 0.3s ease-in;
  }

  &.visible:not(.customAnimations) {
    visibility: visible;

    .title,
    .text,
    .counters,
    .animated,
    .button {
      opacity: 1;
      transform: scale(1);
      -moz-opacity: 1;
      -moz-transform: scale(1);
      -webkit-opacity: 1;
      -webkit-transform: scale(1);
    }

    .text {
      transition-delay: 0.3s;
    }
    .counters {
      transition-delay: 0.6s;
    }
    .button {
      transition: border-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s,
        opacity 0.5s ease 0.9s, transform 0.5s ease 0.9s, moz-opacity 0.5s ease 0.9s,
        -moz-transform 0.5s ease 0.9s, -webkit-opacity 0.5s ease 0.9s,
        -webkit-transform 0.5s ease 0.9s, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in;
    }
  }

  &.hidden:not(.customAnimations) {
    visibility: visible;

    .button {
      transition: border-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s, opacity 0.5s ease,
        transform 0.5s ease, moz-opacity 0.5s ease, -moz-transform 0.5s ease,
        -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease, background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in;
    }
    .title {
      transition-delay: 0.9s;
    }
    .text {
      transition-delay: 0.6s;
    }
    .counters {
      transition-delay: 0.3s;
    }
  }

  &.customAnimations {
    .counters,
    .text {
      transition-delay: 0 !important;
    }

    &.visible {
      .text:not(.sAnimated) {
        opacity: 1;
        transform: scale(1);
        -moz-opacity: 1;
        -moz-transform: scale(1);
        -webkit-opacity: 1;
        -webkit-transform: scale(1);
      }

      .sAnimated {
        transition-delay: 0 !important;

        &.animateIn {
          transition-delay: 0 !important;

          opacity: 1;
          transform: scale(1);
          -moz-opacity: 1;
          -moz-transform: scale(1);
          -webkit-opacity: 1;
          -webkit-transform: scale(1);
        }

        &.animateOut {
          transition-delay: 0 !important;

          opacity: 0;
          transform: scale(0);
          -moz-opacity: 0;
          -moz-transform: scale(0);
          -webkit-opacity: 0;
          -webkit-transform: scale(0);
        }
      }
    }
  }
}

.subtitle {
  color: $txt-light;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 1.25em;
  font-family: Nexa;
  white-space: pre-line;
  margin: 0 !important;

  @include media-breakpoint-down(md) {
    font-size: 1.1em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.95em;
  }
}

.pageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95vh;
  pointer-events: none;
  overflow: hidden;

  div {
    background: linear-gradient(0deg, $layout-bg, transparent 25%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2;
    object-position: center center;
  }
}
