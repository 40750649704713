@import '../../styles/index.scss';

@keyframes linkFadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes linkFadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

.navbar {
  position: fixed;
  height: 5em;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $txt;
  z-index: 100;

  .logo {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: -3px;
    z-index: 9999999;
    width: 125px;

    @include media-breakpoint-down(sm) {
      top: 4px;
    }
  }

  padding-bottom: 0;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.1s ease-in-out, filter 1s ease-in-out;

  &.hasBg {
    background: linear-gradient($layout-bg 90%, transparent 100%);

    .scrollIndicator {
      hr:nth-child(2) {
        opacity: 1;
        flex: 1;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    width: calc(100% - 15%);
    margin: 0 7.5%;
    padding-top: 1em;
    padding-bottom: 1em;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-bottom: 0;

      &.centeredOnSm {
        justify-content: center;

        & > div {
          width: 125px;
        }
      }
    }

    & > ul {
      display: flex;
      list-style: none;

      margin-bottom: 0 !important;
      margin-top: 0.25em;

      li {
        &:not(:last-child) {
          margin-right: 1.5em;
        }

        &,
        * {
          color: $txt;
          background: none !important;
          border: none !important;
          cursor: pointer;
          font-size: 1.11em;
          text-decoration: none;
          transition: color 0.3s ease;
          padding: 0 !important;

          &:hover {
            color: lighten($primary, 20);
          }

          &.active {
            font-weight: bold;
          }
        }
      }
    }
  }

  .contactButton {
    padding: 0 5px;
    width: auto;
    letter-spacing: 1px;
    margin: 0;
    margin-right: 10px;
    line-height: 25px;
    font-size: 15px;

    @keyframes rollin {
      0% {
        transform: translateY(-100px);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation: rollin 0.4s ease-out;
  }

  .burgerWrapper {
    display: flex;
    flex-direction: row;

    .iconTitle {
      display: inline;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      font-size: 15px;
      margin-top: 2px;
      margin-right: 1em;
      font-weight: bold;

      @include media-breakpoint-up(lg) {
      }

      @include media-breakpoint-down(sm) {
        padding-left: 1em;
      }
    }

    .burger {
      display: flex;
      flex-direction: column;
      width: 20px;

      &:hover {
        cursor: pointer;
      }

      .bar {
        display: flex;
        width: 100%;
        height: 2px;
        background-color: $txt;
        margin: 3px 0;
        transition: margin-left 0.3s ease-in-out;

        &:first-child {
          width: 40%;
          margin-left: 45%;
        }

        &:last-child {
          width: 60%;
          margin-left: 20%;
        }
      }

      &.active {
        .bar {
          &:first-child {
            margin-left: 15%;
          }
          &:last-child {
            margin-left: 40%;
          }
        }
      }
    }
  }

  .scrollIndicator {
    height: 4px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;

    hr {
      display: flex;
      margin: 0;
      padding: 0;
      border: none;
      height: 100%;
      transition: width 0.1s linear;

      &:nth-child(1) {
        background-color: $primary;
      }

      &:nth-child(2) {
        opacity: 0;
        background-color: $primary-dark;
      }
    }
  }
}

.menu {
  z-index: 900;
  top: 7em;
  position: fixed;
  width: 100%;
  height: calc(100vh - 7em);
  overflow-y: auto;

  @include media-breakpoint-down(md) {
    top: 5em;
    height: calc(100vh - 5em);

    display: flex;
    flex-direction: column;
  }

  &.active {
    display: flex;
  }

  &:not(.active) {
    display: none;
  }

  .links,
  .takeMeHome {
    position: absolute;
    top: calc(40% - 4.5em);
    width: 100%;
    height: 5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .langs {
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-top: 3em;

      @include media-breakpoint-down(sm) {
        margin-top: 0.5em;
        margin-bottom: 5em;
      }

      .langLink {
        padding: 0px 0.75em;
        width: 75px;

        &.active {
          img {
            border-color: $primary;
          }
        }

        img {
          border: 3px solid $primary-dark;
          transition: border 0.3s ease-in-out;
          cursor: pointer;
          border-radius: 100%;
          max-width: 100%;
        }

        img:hover {
          border-color: $primary;
        }

        @include media-breakpoint-down(md) {
          margin-top: 1em;
        }
      }
    }

    .link {
      cursor: pointer;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      opacity: 0;
      margin-top: 3em;

      animation: linkFadeIn 0.25s forwards;

      a,
      button {
        color: $txt;
        text-decoration: none !important;
        font-size: 35px;
        font-weight: bolder;
        -webkit-tap-highlight-color: transparent;
        border: none;
        background-color: transparent;
        border-radius: 0;
        font-family: NexaLight;
        cursor: pointer !important;
        outline: none;

        @include media-breakpoint-down(md) {
          font-size: 30px;
          margin: 0.5em 0;
        }
      }
    }

    &.onBeforeClose {
      .link.loaded {
        animation: linkFadeOut 0.25s backwards;
      }
      .link:not(.loaded) {
        animation: none;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: space-between;
      top: 0;
      height: auto;
      align-items: flex-start;
      left: 0%;
      position: relative;

      .link {
        border-left: none !important;
        width: auto;
        margin: auto;
        height: 5em;
        margin-top: 0.25em;
      }
    }
  }

  .takeMeHome {
    margin-top: auto;
    z-index: -1;
    bottom: 1em;

    a {
      color: $txt;
      text-decoration: none;
      font-size: 22px;

      &:hover {
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      top: unset;
      bottom: unset;
      width: 100%;
      height: 25px;
      justify-content: flex-end;
      align-items: center;
      left: 0;

      position: relative;
      margin-top: 2.5em;
    }
  }
}
