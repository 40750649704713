@import '../../styles/index.scss';

.content {
  min-height: calc(100vh - 100px);
}

.logo {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  border: 30px solid $primary;

  @include media-breakpoint-down(sm) {
    width: 100px;
    height: 100px;
    border-width: 24px;
  }
}

.heading {
  margin-top: 0.25em;
  color: $txt;
  font-size: 4em;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 2em;
  }
}

.links {
  margin-top: 2.5em;
  display: flex;

  @include media-breakpoint-down(sm) {
    margin-top: 1.5em;
  }

  & > * {
    color: $txt;
    margin: 0 0.5em 0 0.9em;
    font-size: 1.5em;
    transition: color 0.3s ease;
    text-decoration: none !important;
    display: block;
    position: relative;

    @include media-breakpoint-down(sm) {
      font-size: 1.25em;
    }

    &::after {
      content: ' ';
      display: block;
      width: 0.2em;
      height: 0.2em;
      background-color: $txt;
      border-radius: 100%;
      z-index: 1000;
      position: absolute;
      left: -0.3em;
      bottom: 0.4em;
    }

    &::first-letter {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }
}

.downloadAll {
  font-size: 1.25em;
  color: $txt !important;
  margin-top: 0.5em;
  text-decoration: none !important;
  transition: color 0.3s ease;
  text-align: center;

  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }

  &:hover {
    color: $primary !important;
  }
}
