@import '../../styles/index.scss';

@keyframes fading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.fallbackText {
  display: inline-flex;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 80%;
  margin: 0 auto;
}

.header {
  color: $txt;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 0.5s ease-in-out;

  h1 {
    font-size: 4em;
    z-index: 1;
  }

  .logo {
    width: 20vh;
    height: 20vh;
    box-sizing: border-box;
    border: 5.5vh solid #1a2027;
    border-radius: 50%;
    position: absolute;
    animation-name: pulse;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: forwards;
  }

  &.loading {
    height: 100vh;

    .logo {
      animation-iteration-count: infinite;
    }
  }
}

.root {
  color: $txt;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4.5em;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.blogItem {
  color: $txt;
  border-radius: $border-radius-lg;
  overflow: hidden;
  background: #1a2027 !important;
  background-color: #1a2027 !important;

  border: 1px solid transparent;
  transition: border 0.3s ease-in-out;
  cursor: pointer;

  &,
  &:visited,
  &:focus,
  &:active {
    color: $txt !important;
    text-decoration: none !important;
  }

  &:hover {
    border-color: $primary;
  }

  min-width: calc(50% - 1em) !important;
  max-width: calc(50% - 1em) !important;
  margin: 0.5em;
  flex: 0.5;

  @include media-breakpoint-down(sm) {
    flex: 1;
    min-width: calc(100% - 1em) !important;
    max-width: calc(100% - 1em) !important;
  }

  :global(.card-img-top) {
    height: 15em;
    object-fit: cover;
  }

  :global(.card-body) {
    display: flex;
    flex-direction: column;
    z-index: 2;

    a,
    a:hover,
    a:visited,
    a:focus,
    a:active {
      color: $primary;
    }
  }

  :global(.card-title) {
    font-size: 1.5em;

    @include media-breakpoint-down(sm) {
      text-overflow: unset;
    }
  }

  :global(.card-text) {
    font-size: 1em;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .footer {
    font-size: 0.8em;
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }

  &.placeholder {
    pointer-events: none !important;
    animation: fading 2s infinite;
  }
}
