@import '../../styles/index.scss';

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.loading {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    width: 20vh;
    height: 20vh;
    box-sizing: border-box;
    border: 5.5vh solid #1a2027;
    border-radius: 50%;
    position: absolute;
    animation: pulse 1s infinite;
  }
}

.header {
  min-height: 35vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  flex-direction: column;
  margin-bottom: 5em;
  padding: 5em 0;

  @include media-breakpoint-down(sm) {
    min-height: 25vh;
    padding: 2em 0;
  }

  .background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.35;
  }

  .title {
    position: relative;
    text-align: center;
    z-index: 2;
    color: $txt;
    font-size: 4em;
    font-family: NexaBold;

    @include media-breakpoint-down(sm) {
      font-size: 3em;
    }
  }

  .publicationDate {
    font-size: 1.1em;
    color: $txt;
    font-family: NexaBold;
    z-index: 2;

    @include media-breakpoint-down(sm) {
      font-size: 1em;
    }
  }
}

.root {
  color: $txt;
  text-align: center;
  max-width: 100%;
  overflow-x: hidden;
  overflow-wrap: break-word;

  a,
  a:hover,
  a:visited,
  a:focus,
  a:active {
    color: lighten($primary, 7.5);
    font-family: NexaBold;
  }

  img {
    max-width: 90vw;
    object-fit: contain;
  }
}

.latestPostsHeading {
  color: $txt;
  text-align: center;
  margin-bottom: 4rem;
}
