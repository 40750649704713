@import '../../styles/index.scss';

.aboutUsPage {
  .aboutUsMini {
    h1,
    h2 {
      margin: 75px 0 50px 0;
    }
  }

  & > section {
    &:not(:first-child) {
      z-index: 1;
    }
  }
}
