@import '../../styles/index.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
  }
  to {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes logoAnimation {
  0% {
    opacity: 0;
    border-width: 0;
  }
  100% {
    opacity: 1;
    border-width: 30px;
  }
}

.logoCircle {
  border: 0px solid $primary;
  border-radius: 100%;
  height: 120px;
  width: 120px;
  box-sizing: border-box;
  animation: logoAnimation 0.5s ease-in-out forwards;
  margin-bottom: 3em;
  margin-top: 25vh;

  transition: top 0.5s ease-in-out, left 0.5s ease-in-out, border-color 0.3s ease-in-out;

  @include media-breakpoint-down(lg) {
    height: 110px;
    width: 110px;
  }

  &.show {
    border-color: $primary !important;
  }

  &.hide {
    border-color: transparent !important;
  }
}

.homeWrapper {
  width: 100%;

  .home {
    opacity: 1;
    display: flex;
    color: $txt;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    align-items: center;

    .tile {
      min-width: 40% !important;

      @include media-breakpoint-down(md) {
        min-width: 90% !important;
      }
    }

    .banner {
      transition: all 1s ease-in-out;
      position: relative;
      flex: 1;
      width: 100%;
      flex-shrink: 0;
      min-height: 80vh;
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .headline {
        display: flex;
        flex-direction: column;
      }

      .title {
        color: $txt;
        margin: 0;
        font-weight: bold;
        font-size: 35px;
        padding: 0 20%;
        text-align: center;
        line-height: 1.3em;
        white-space: pre-wrap;

        @include media-breakpoint-down(lg) {
          padding: 0 15%;
        }

        @include media-breakpoint-down(md) {
          font-size: 27.5px;
          padding: 0 10%;
        }

        @include media-breakpoint-down(sm) {
          font-size: 22.5px;
          padding: 0 5%;
        }

        @media screen and (max-height: 700px) {
          margin-top: 0;
        }
      }

      .subtitle {
        color: $txt-light;
        text-align: center;
        margin: 16px auto 0;
        width: 100%;
        font-weight: bold;
        font-size: 1.25em;
        font-family: Nexa;
        white-space: pre-line;

        @include media-breakpoint-down(md) {
          font-size: 1.1em;
        }
        @include media-breakpoint-down(sm) {
          font-size: 0.95em;
        }
      }
    }

    .aboutUs {
      .scroller {
        margin: 50px auto;
      }
    }
  }

  .title {
    color: $txt;
    margin: 0;
    font-size: 2.5em;
    padding: 0 20%;
    text-align: center;
    line-height: 1.3em;
    white-space: pre-wrap;
    margin-bottom: 32px;

    @include media-breakpoint-down(sm) {
      font-size: 2.75em;
    }
  }

  .sectionTitle {
    color: $txt;
    font-size: 35px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 25px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }
}

.blogSection {
  & > * {
    opacity: 0;
  }

  &.visible {
    & > h2,
    & > main,
    & > a {
      animation: fadeIn 0.3s forwards ease;
    }

    & > h2 {
      animation-delay: 0s;
    }
    & > main {
      animation-delay: 0.3s;
    }
    & > a {
      animation-delay: 0.6s;
    }
  }

  &:not(.visible) {
    & > h2,
    & > main,
    & > a {
      animation: fadeOut 0.3s backwards;
    }

    & > h2 {
      animation-delay: 0.6s;
    }
    & > main {
      animation-delay: 0.3s;
    }
    & > a {
      animation-delay: 0s;
    }
  }
}

.pageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  max-height: 95vh;

  div {
    background: linear-gradient(0deg, $layout-bg, transparent 25%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.15;
    object-position: center center;
  }
}
