/* html {
  scroll-behavior: smooth;
} */

html,
body {
  background-color: #10161d !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  display: block;
  height: 100vh;
  max-height: 100vh;
  font-family: NexaLight;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
button,
li {
  -webkit-tap-highlight-color: transparent;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #141920 inset !important;
  -webkit-box-shadow: 0 0 0 30px #141920 inset !important;
  -webkit-text-fill-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3em;
  min-width: 0;
  text-rendering: geometricPrecision !important;
  max-width: 100vw;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.cursor-pointer {
  cursor: pointer !important;
}

.underline {
  text-decoration: underline !important;
}
