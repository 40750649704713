@import '../styles/index.scss';

@keyframes logoAnimation {
  0% {
    opacity: 0;
    border-width: 0;
  }
  100% {
    opacity: 1;
    border-width: 30px;
  }
}

.layoutWrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: $layout-bg;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  position: relative;
  flex-direction: column;
  font-family: $fontstack;

  @include media-breakpoint-down(md) {
    background-size: 125%;
  }

  @include media-breakpoint-down(md) {
    background-size: 150%;
  }

  @include media-breakpoint-down(sm) {
    background-size: 175%;
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 2;

    .logoCircle {
      border: 0px solid $primary;
      border-radius: 100%;
      height: 120px;
      width: 120px;
      left: calc(50% - 60px);
      position: fixed;
      top: 40%;
      box-sizing: border-box;
      transform: translate3d(0);
      animation: logoAnimation 0.5s ease-in-out forwards;

      transition: top 0.5s ease-in-out, left 0.5s ease-in-out, border-color 0.3s ease-in-out;

      @include media-breakpoint-down(lg) {
        height: 110px;
        width: 110px;
        left: calc(50% - 55px);
        top: 30%;
      }

      @include media-breakpoint-down(md) {
        top: 25%;
      }

      @include media-breakpoint-down(sm) {
        top: 25%;
      }

      &.show {
        border-color: $primary !important;
      }

      &.hide {
        border-color: transparent !important;
      }
    }
  }

  .layout {
    opacity: 0;
    z-index: 1;
    flex: 1;
    padding-top: 5em;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;

    &.visible {
      opacity: 1;
      animation-delay: 0;
    }

    &.hidden {
      opacity: 0;
      animation-delay: 0;
    }
  }
}

.blurry {
  filter: blur(9px);
}

.cookiesContent {
  flex: 1 !important;
  margin-bottom: 0 !important;
}

.cookiesContainer {
  left: 15px !important;
  bottom: 15px !important;
  font-size: 14px !important;
  z-index: 2147483647 !important; // overriding zIndex of chatbot
  width: 200px !important;
  border-radius: 16px !important;
}
