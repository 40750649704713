@import '../../styles/index.scss';

.arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $txt-light;

  .button {
    height: 44px;
    width: 44px;
    border: none;
    padding: 0 0.5em;
    font-weight: 300;
    background: #1a2028;
    overflow: hidden;
    border-radius: 8px;
    color: white;
    opacity: 1;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    outline: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: 0.5;
    }

    &:hover,
    &:active {
      &:not(:disabled) {
        color: $primary;
      }
    }

    &:first-of-type {
      margin-right: 12px;
    }

    &:last-of-type {
      margin-left: 12px;
    }

    .loader {
      width: 1.5em;
      height: 1.5em;
      border-width: 0.15em;
      opacity: 0.7;
    }
  }

  @include media-breakpoint-down(sm) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
