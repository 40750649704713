@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/bootstrap';

// MAIN VARIABLES, DO NOT CHANGE
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$body-bg: #f9f9f9;

// CHANGES HERE

@font-face {
  font-family: Nexa;
  src: url('./fonts/Fontfabric-NexaLight.otf');
  font-display: fallback;
}

@font-face {
  font-family: NexaBold;
  src: url('./fonts/NexaBold.otf');
  font-display: fallback;
}

@font-face {
  font-family: NexaLight;
  src: url('./fonts/Fontfabric-NexaLight.otf');
  font-display: fallback;
}

// FOR EXAMPLE =====> $primary: red;
$primary: #288ba0;
$primary-dark: #384a4e;
$light: #f3f3f3;
$red: #e01e21;
$dark: #1b1f27;

// NEW VARIABLES
$layout-bg: #10161d;
$link: #ffffff;
$link-selected: #f2f2f2;
$txt: white;
$txt-light: #f2f2f2;
$txt-dark: grey;
$txt-darkest: #3b4146;

$fontstack: NexaLight;

$bradius: 3.66px;

// THEME COLORS
// $theme-colors: (
//   primary: yellow,
// );
