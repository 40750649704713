@import '../../styles/index.scss';

.solutions {
  .title {
    color: $txt;
    font-size: 40px;
    margin-top: 75px;
    margin-bottom: 50px;
    text-align: center;
    width: 100%;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      margin-bottom: 50px;
    }
  }

  .subtitle {
    color: $txt;
    margin-bottom: 100px;
    text-align: center;
    font-size: 16px;

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }
  }
}
