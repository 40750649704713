@import '../../styles//index.scss';

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.footer {
  // background-color: $layout-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
  opacity: 0;
  animation: grow 1s forwards;

  .title,
  .avf,
  .certifications,
  .btnSection,
  .socialMediaSection,
  .clutchReviewSection {
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &.animateIn {
    .avf,
    .title,
    .btnSection,
    .certificationSection,
    .socialMediaSection,
    .clutchReviewSection {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
    }
    .certificationSection {
      transition-delay: 0.3s;
    }
    .socialMediaSection {
      transition-delay: 0.3s;
    }
  }

  &.animateOut {
    .avf,
    .title,
    .btnSection,
    .certificationSection,
    .socialMediaSection,
    .clutchReviewSection {
      opacity: 0 !important;
      transform: scale(0) !important;
    }
  }

  .title {
    font-size: 40px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 50px;
    color: $txt;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .btnSection {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .button {
      transition: box-shadow 0.3s ease-in;

      &.active {
        box-shadow: 1px 1px 30px lighten($layout-bg, 10);
      }
    }
  }

  .certificationSection {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      transition: opacity 0.3s ease-in-out;
      opacity: 0.5;
      height: 100px;
      object-fit: cover;
      width: 170px;
      object-position: center;
      transform: scale(1.5);

      &:hover {
        opacity: 1;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .socialMediaSection {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    .icon {
      color: $txt-dark;
      font-size: 25px;
      transition: color 0.3s ease-in-out;
      margin: 0.5em;

      &:hover {
        color: $txt;
      }
    }
  }

  .loaderSection {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .title {
      margin-bottom: 40px;
      font-size: 16px;
      color: $primary;
      letter-spacing: 5px;
      text-transform: uppercase;
      text-align: center;
    }

    .loaderWrapper {
      position: relative;
      height: 445px;
      width: 130px;

      .backDropWrapper,
      .frontWrapper {
        width: 130px;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;

        .loaderBar {
          width: 4px;
          display: block;
          margin: auto;
          background-color: $primary;
          height: 200px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        .loaderImg {
          margin: auto;
          width: 130px;
          height: 240px;
        }
      }

      .backDropWrapper {
        filter: saturate(0.4) brightness(0.5) contrast(0.9);
      }

      .frontWrapper {
        height: 0%;
        // transition: height 0.1s ease;
      }
    }
  }

  .avf {
    width: 200px;
    max-width: 50vw;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 100%;
      opacity: 0.5;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 1;
      }
    }

    @include media-breakpoint-down(sm) {
      transform: scale(1.75) !important;
      margin-bottom: 1.5em;

      img {
        max-width: 50%;
      }
    }
  }

  .endRenderSection {
    .content {
      color: $txt;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;

      transition: all 0.5s ease-in-out;

      .title {
        line-height: 48px;
        font-size: 40px;
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 60px;
        display: -webkit-box;
        cursor: pointer;
      }
      .logo {
        margin-bottom: 50px;
        width: 130px;
      }
      .copyright {
        font-size: 13.33px;
        margin-top: 0;
        margin-bottom: 50px;
        color: $txt;
        text-align: center;
      }

      @include media-breakpoint-down(md) {
        .title {
          font-size: 30px;
        }
      }
    }
  }
}

.link {
  color: $txt !important;
  text-decoration: underline;
  cursor: pointer;
}
