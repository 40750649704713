@import '../../styles/index.scss';

.anchor {
  font-style: italic;
  color: white;
  text-decoration: underline;
  margin: 0 auto;

  &:hover {
    color: white;
    text-decoration: none;
  }
}
