@import '../../styles//index.scss';

.mediaWrapper {
  display: flex;
  flex-direction: column;

  .heading {
    opacity: 0;
    transform: scale(0);
    color: $txt;
    font-size: 40px;
    font-family: Nexa;
    margin-bottom: 50px;
    font-weight: normal;
    text-align: center;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    &.animateIn {
      opacity: 1;
      transform: scale(1);
    }

    &.animateOut {
      opacity: 0;
      transform: scale(0);
    }
  }

  .cardsWrapper {
    opacity: 1;
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: none !important;
      color: unset;
    }

    .mediaCard {
      opacity: 0;
      transform: scale(0);
      width: 275px;
      height: 275px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      background-color: transparent;
      flex-direction: column;
      margin: 15px;
      border-radius: $bradius;
      border: 1px solid $txt;
      border-color: $txt;
      transition: border-color 0.5s ease-in-out, background-color 0.5s ease-in-out,
        opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
      padding: 8px;
      cursor: pointer;

      &.animateIn {
        opacity: 1;
        transform: scale(1);
      }

      &.animateOut {
        opacity: 0;
        transform: scale(0);
      }

      &:hover {
        border-color: $primary;
        background-color: $primary;

        .link {
          color: $txt;
        }
      }

      .logo {
        max-width: 70%;
        object-fit: contain;
        object-position: center;
        margin: auto;
        height: 80px;
      }

      .title {
        font-size: 21px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
        width: 100%;
        color: $txt;
      }

      .link {
        color: $txt;
        text-decoration: none;
        font-weight: bold;
        margin-bottom: 1em;
        margin-top: 1em;
        font-size: 14px;
        transition: color 0.3s ease-in-out;

        .icon {
          margin-left: 4px;
        }
      }
    }
  }
}
