.cc_ambitas {
  --cc-bg: #181b1d;
  --cc-text: #d8e5ea;
  --cc-btn-primary-bg: #258094;
  --cc-btn-primary-text: #fff;
  --cc-btn-primary-hover-bg: #17a2b8;
  --cc-btn-secondary-bg: #33383c;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #454c54;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-bg);
  --cc-block-text: #b3bfc5;
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}
