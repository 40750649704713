@import '../../styles/index.scss';

@keyframes comeIn1 {
  0% {
    transform: translate(-100px, -100px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes comeIn2 {
  0% {
    transform: translate(100px, -100px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes comeIn3 {
  0% {
    transform: translate(100px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.mainRefWrapper {
  max-width: 250px;
  margin: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  .mainRefLink {
    margin: auto;
    color: white !important;
    text-decoration: none !important;
    display: block;
    margin-top: -10px;
    margin-bottom: 40px;
    padding: 10px;

    h4 {
      margin-right: 73px;
      font-size: 14.4px;
    }

    img {
      max-height: 30px;
      min-height: 30px;
      display: block;
    }
  }
}

.logos {
  width: 100vw;
  color: white;

  .referencesSubtitle {
    text-align: center;
    font-weight: bold;
    font-size: 14.44px;
    color: white !important;
    text-decoration: none !important;
    width: auto;
    align-self: center;
  }

  .title {
    opacity: 0;
    transform: scale(0);
    color: $txt;
    font-size: 40px;
    font-family: Nexa;
    margin-bottom: 50px;
    font-weight: normal;
    text-align: center;

    transition: opacity 0.5s ease, transform 0.5s ease;

    &.fadeIn {
      opacity: 1;
      transform: scale(1);
    }

    &.fadeOut {
      opacity: 0;
      transform: scale(0);
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  .headliner {
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s ease, transform 0.5s ease;

    // &.significant {
    font-size: 1.33rem;
    line-height: 1.5em;
    margin-bottom: 6px;

    @include media-breakpoint-down(md) {
      line-height: 1.25em;
    }
    // }

    &.fadeIn {
      opacity: 1;
      transform: scale(1);
    }

    &.fadeOut {
      opacity: 0;
      transform: scale(0);
    }
  }

  .logoBackground {
    background-image: url('../../assets/images/herosection/Circle.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 112px;
    top: 0;
    height: 48vw;
    width: 48vw;
    max-width: 650px;
    max-height: 650px;
    z-index: -1;

    @include media-breakpoint-down(md) {
      right: 60px;
    }
    @include media-breakpoint-down(sm) {
      right: 25%;
      top: 100px;
    }
    @media (max-width: 500px) {
      right: 22%;
      height: 55vw;
      width: 55vw;
      top: 95px;
    }
    @media (max-width: 400px) {
      top: 115px;
    }
  }

  .swipeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    &.disabled {
      touch-action: none;
      -ms-touch-action: none;
      pointer-events: none;
    }

    .swipeCard {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      flex: 1;
      width: 100%;
      min-height: 650px;

      .logoWrapper {
        display: flex;
        padding: 0 7.5%;
        flex: 1;
        align-items: center;
        overflow: hidden;
        opacity: 1;
        transform: scale(1);
        transition: all 0.5s ease-in-out;
        position: relative;

        .imgWrapper {
          position: absolute;
          width: calc(100% - 7.5%);
          height: 100%;

          &.small {
            height: 350px;
            position: static;
            width: 100%;

            @media (max-width: 700px) {
              height: 300px;
            }
            @media (max-width: 500px) {
              height: 280px;
            }
          }
        }

        .textSection {
          opacity: 0;
          z-index: 2;
          transform: scale(0);
          transition: opacity 0.5s ease-in, transform 0.5s ease-out;
          max-width: 50%;

          &.fadeIn {
            opacity: 1;
            transform: scale(1);
          }

          &.fadeOut {
            opacity: 0;
            transform: scale(0);
          }

          h2,
          h2 > * {
            font-size: 36px;
            line-height: 48px;
            margin-bottom: 18px;
            text-decoration: none !important;
            color: $txt;

            @include media-breakpoint-down(sm) {
              font-size: 30px;
            }
          }

          p {
            max-width: 432px;
            a {
              color: $primary;
            }
          }

          h2,
          h2 > *,
          p {
            text-align: left;

            @include media-breakpoint-down(sm) {
              text-align: center;
            }
          }

          .tag {
            padding: 4px 12px;
            border-radius: 8px;
            color: white;
            background: rgba(255, 255, 255, 0.08);
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            font-family: Nexa;
            text-wrap: nowrap;

            &.inverted {
              color: $layout-bg;
              background: rgba(255, 255, 255, 0.9);
            }

            &:not(:last-child) {
              margin-right: 8px;
            }
          }

          .infoBoxWrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 8px;

            .infoBox {
              border: 1px solid #42464b;
              box-sizing: border-box;
              border-radius: 8px;
              padding: 16px;
              font-size: 12px;
              line-height: 16px;
              color: white;
              font-family: Nexa;
              font-weight: bold;
              width: 130px;
              margin: 16px 16px 0 0;

              & > div:last-child {
                font-size: 16px;
                margin-top: 12px;
                line-height: 16px;
              }
            }
          }

          .companyLogo img {
            max-width: 130px;
            max-height: 80px;
            opacity: 0.5;
          }

          .more {
            color: white;
            opacity: 0.6;
            font-weight: bold;
            transition: opacity 0.3s ease;

            &:hover {
              opacity: 1;
            }

            svg {
              font-size: 13px;
            }
          }
        }

        .small {
          display: none;
        }

        @include media-breakpoint-down(sm) {
          &,
          .small {
            display: block;
          }

          .imgWrapper:not(.small) {
            display: none;
          }

          .textSection {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
              max-width: 500px;
              text-align: center;
            }

            .infoBoxWrap {
              justify-content: center;

              .infoBox {
                margin: 16px 8px 0;
              }
            }
          }
        }

        .logo {
          position: absolute;
          right: 262px;
          top: -100px;
          transform: scale(0);
          object-fit: fill;
          object-position: center;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.2s ease-in, transform 0.5s ease-out;
          width: 56%;
          max-width: 725px;

          /* Enable hardware acceleration to fix laggy transitions */
          -webkit-transform: translateZ(0);
          -moz-transform: translateZ(0);
          -ms-transform: translateZ(0);
          -o-transform: translateZ(0);
          transform: translateZ(0), scale(0);

          &.fadeIn {
            opacity: 1;
            transform: scale(1);
          }

          &.fadeOut {
            opacity: 0;
            transform: scale(0);
          }

          &.clickable {
            cursor: pointer;
          }

          &:not(.background) {
            transition-delay: 0.5s;
          }

          &.background {
            transform: translate(-100px, -100px);
            opacity: 0;

            &.phoneIntro {
              transform: translate(0, 0);
              opacity: 1;
            }
          }

          &.secondScreen {
            right: -75px;
            top: 15px;

            &:not(.background) {
              transition-delay: 0.75s;
            }

            &.background {
              transform: translate(100px, -100px);

              &.phoneIntro {
                transform: translate(0, 0);
              }
            }
          }

          &.thirdScreen {
            right: 100px;
            top: 220px;

            &:not(.background) {
              transition-delay: 1s;
            }

            &.background {
              transform: translate(100px, 100px);

              &.phoneIntro {
                transform: translate(0, 0);
              }
            }
          }

          @include media-breakpoint-down(lg) {
            right: 190px;
            top: -90px;

            &.secondScreen {
              right: -85px;
              top: 0;
            }

            &.thirdScreen {
              right: 64px;
              top: 175px;
            }

            &.macbook {
              top: 81px;
              right: 87px;
            }

            &.ipad {
              right: 114px;
              top: -130px;

              &.portrait {
                width: 56%;
                &.secondScreen {
                  right: 90px;
                  top: 90px;
                }
              }
              &.secondScreen {
                right: -50px;
                top: 40px;
              }
            }
          }

          @include media-breakpoint-down(md) {
            right: 140px;
            top: -93px;

            &.secondScreen {
              right: -110px;
              top: -15px;
            }

            &.thirdScreen {
              right: 25px;
              top: 140px;
            }

            &.macbook {
              top: 65px;
              right: 45px;
            }

            &.ipad {
              &.portrait {
                right: -50px;
                top: -20px;
              }
              right: 40px;
              top: -100px;
            }
          }

          @media (max-width: 850px) {
            right: 125px;
            top: -65px;

            &.secondScreen {
              right: -70px;
              top: -5px;
            }

            &.thirdScreen {
              right: 35px;
              top: 122px;
            }

            &.ipad {
              &.portrait {
                top: 0px;

                &.secondScreen {
                  right: 70px;
                }
              }
            }
          }

          @include media-breakpoint-down(sm) {
            top: 50px;
            left: 70px;

            &.secondScreen {
              top: 108px;
              left: 239px;
            }

            &.thirdScreen {
              top: 215px;
              left: 147px;
            }

            &.macbook {
              top: 110px;
              right: calc(50% - 41%);
              left: unset;
              width: 80%;
            }

            &.ipad {
              right: 11px;
              top: 11px;
              width: 65%;

              &.portrait {
                width: 65%;
                top: 46px;
                left: 170px;

                &.secondScreen {
                  top: 140px;
                  left: 60px;
                }
              }

              &.secondScreen {
                top: 118px;
                left: 200px;
              }
            }
          }

          @media (max-width: 700px) {
            top: 7%;
            left: 10%;

            &.secondScreen {
              top: 15%;
              left: 35%;
            }

            &.thirdScreen {
              top: 29%;
              left: 21%;
            }

            &.ipad {
              right: 3%;
              left: 7%;

              &.secondScreen {
                top: 15%;
                left: 30%;
              }
            }
          }
          @media (max-width: 600px) {
            top: 9%;

            &.thirdScreen {
              top: 26%;
            }

            &.ipad {
              top: 45px;

              &.portrait {
                top: 70px;
                left: 150px;
              }

              &.secondScreen {
                top: 17%;
                left: 28%;
              }
            }
          }
          @media (max-width: 500px) {
            width: 82%;
            top: 5%;
            left: -9%;

            &.secondScreen {
              top: 12%;
              left: 27%;
            }

            &.thirdScreen {
              top: 25%;
              left: 7%;
            }

            &.macbook {
              right: 0;
              width: 100%;
            }

            &.ipad {
              width: 80%;
              left: 5%;
              top: 30px;

              &.portrait {
                width: 80%;
                top: 70px;
                left: 73px;
                &.secondScreen {
                  top: 150px;
                  left: -10px;
                }
              }

              &.secondScreen {
                bottom: -10%;
                left: 25%;
              }
            }
          }
          @media (max-width: 400px) {
            top: 10%;

            &.secondScreen {
              top: 16%;
            }

            &.thirdScreen {
              top: 26%;
              left: 7%;
            }

            &.ipad {
              left: -5%;
              top: 9%;

              &.portrait {
                top: 80px;
                left: 60px;
                &.secondScreen {
                  top: 140px;
                }
              }

              &.secondScreen {
                top: 18%;
                left: 20%;
              }
            }
          }
        }

        .macbook {
          top: 81px;
          right: 87px;
        }

        .ipad {
          &.portrait {
            width: 61%;
            right: -90px;
            top: -50px;

            &.secondScreen {
              right: 125px;
              top: 115px;
            }
          }
          width: 57%;
          max-width: 800px;

          right: 130px;
          top: -160px;

          &.secondScreen {
            right: -75px;
            top: 40px;
          }
        }
      }

      &.hideInactive {
        .logoWrapper:not(.active) {
          max-width: 0;
          padding: 0;
          opacity: 0;
          transform: scale(0);
          height: 0;
        }

        .logoWrapper.active {
          max-width: unset;
        }
      }
      @include media-breakpoint-down(sm) {
        min-height: 700px;
      }

      @media (max-width: 500px) {
        min-height: 725px;
      }
    }
  }

  .closeCase {
    color: $txt;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    align-content: flex-end;
    align-items: flex-end;
    transition: color 0.5s ease;
    font-size: 16px;

    &:focus,
    &:hover {
      color: $primary;
    }
  }

  .dots {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    // opacity: 0;
    // transform: scale(0);

    margin-top: 1.25em;

    span {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      &:first-child,
      &:last-child {
        flex: 1;
        max-width: calc(50% - 1em);
      }

      &:first-child {
        justify-content: flex-end;

        .dot:first-child:not(:last-child) {
          width: 14px;
          font-size: 0.35em;
        }
      }

      &:last-child {
        justify-content: flex-start;

        .dot:last-child:not(:first-child) {
          width: 12px;
          font-size: 0.25em;
        }
      }
    }

    .dot {
      display: flex;
      border-radius: 100%;
      transition: all 0.3s ease-in-out;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0 0.75em;

      &.selected {
        color: transparent;
        border: 4px solid $primary;
        font-size: 0.75em;
      }

      &:not(.selected) {
        color: $txt-darkest;
        font-size: 0.5em;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .steps {
    display: block;
    font-size: 0;
    margin-top: 25px;

    .step {
      font-size: 0;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;

      .txtWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px 0 0;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          max-width: 50%;
        }

        .title,
        .subtitle,
        .text {
          display: inline-flex;
        }

        .title {
          font-size: 35px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 20px;
          transition: color 0.3s ease-in-out;

          @include media-breakpoint-down(md) {
            font-size: 30px;
          }
        }

        .subtitle {
          color: $primary;
          margin: 0;
          margin-bottom: 15px;
          font-size: 22px;
          font-weight: bold;
          transition: color 0.3s ease-in-out;
        }

        .text {
          color: $txt;
          font-size: 16px;
        }
      }

      // .border02 {
      //   height: 70px;
      //   background-image: linear-gradient(0deg, #000, #000 75%, 	 transparent 75%, transparent 100%);
      //   background-size: 1px 20px;
      //   border: none;
      //   background-repeat: repeat-y;
      // }

      .stepper {
        display: flex;
        width: 2px;
        position: relative;
        font-size: 20px;
        margin: 12px 2em 0 calc(2em - 2px);

        background-image: linear-gradient(
          0deg,
          $primary-dark,
          $primary-dark 75%,
          transparent 75%,
          transparent 100%
        );
        background-size: 2px 20px;
        border: none;
        background-repeat: repeat-y;

        .loader {
          width: 2px;
          display: flex;
          position: absolute;
          background-image: linear-gradient(
            0deg,
            $primary,
            $primary 75%,
            transparent 75%,
            transparent 100%
          );
          background-size: 2px 20px;
          border: none;
          background-repeat: repeat-y;
          height: 50%;

          transition: height 0.1s ease;
        }

        .circle {
          position: absolute;
          left: -8.5px;
          color: $primary-dark;

          &:nth-of-type(1) {
            top: -14px;
          }
          &:nth-of-type(2) {
            bottom: -20px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .title,
        .subtitle,
        .text {
          text-align: center;
          justify-content: center;
        }

        .stepper {
          display: none;
        }
      }
    }
  }
}

.arrows {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    height: 44px;
    width: 44px;
    border: none;
    padding: 0 0.5em;
    font-weight: 300;
    background: #1a2028;
    overflow: hidden;
    border-radius: 8px;
    color: white;
    opacity: 1;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    outline: none !important;

    &:disabled {
      opacity: 0.5;
    }

    &:hover,
    &:active {
      &:not(:disabled) {
        color: $primary;
      }
    }

    &:first-of-type {
      margin-right: 12px;
    }

    &:last-of-type {
      margin-left: 12px;
    }
  }

  @include media-breakpoint-down(sm) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.detailImgWrapper {
  width: 100%;
  height: 450px;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  @include media-breakpoint-down(md) {
    margin-top: 5px;
    margin-bottom: 100px;
  }

  img {
    max-width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit: contain;
  }

  @include media-breakpoint-up(xl) {
    top: -40px;
    left: calc(-50% + 400px);
  }

  @media (max-width: 1440px) and (min-width: 1200px) {
    top: -55px;
  }
  @include media-breakpoint-down(lg) {
    height: 350px;
    left: calc(-50% + 310px);
  }
  @include media-breakpoint-down(md) {
    height: 300px;
    left: calc(-50% + 210px);
  }
  @media (max-width: 800px) {
    height: 300px;
    top: -40px;
  }
  @media (max-width: 600px) {
    left: calc(-50% + 190px);
  }
  @media (max-width: 500px) {
    height: 280px;
    left: 0;
    top: -20px;
  }
  @media (max-width: 400px) {
    height: 280px;
    left: 0;
    top: 0;
  }

  .logo {
    position: absolute;
    right: 230px;
    top: -85px;
    transform: scale(0);
    object-fit: cover;
    object-position: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in, transform 0.5s ease-out;
    width: 620px;
    max-width: 725px;

    /* Enable hardware acceleration to fix laggy transitions */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0), scale(0);

    &.fadeIn {
      opacity: 1;
      transform: scale(1);
    }

    &.fadeOut {
      opacity: 0;
      transform: scale(0);
    }

    &.clickable {
      cursor: pointer;
    }

    &:not(.background) {
      animation: fadeIn 0.5s ease-out forwards;
      animation-delay: 0.5s;
    }

    &.background {
      animation: comeIn1 0.5s ease-out forwards;
    }

    &.secondScreen {
      right: -55px;
      top: 15px;

      &:not(.background) {
        animation-delay: 0.75s;
      }

      &.background {
        animation: comeIn2 0.5s ease-out forwards;
      }
    }

    &.thirdScreen {
      right: 100px;
      top: 195px;
      &:not(.background) {
        animation-delay: 1s;
      }

      &.background {
        animation: comeIn3 0.5s ease-out forwards;
      }
    }

    @include media-breakpoint-down(lg) {
      right: 155px;
      top: -90px;
      width: 530px;

      &.secondScreen {
        right: -85px;
        top: 0;
      }

      &.thirdScreen {
        right: 45px;
        top: 155px;
      }

      &.ipad {
        top: -30px;
        right: 80px;
        width: 600px;

        &.portrait {
          &.secondScreen {
            right: 90px;
            top: 90px;
          }
        }

        &.secondScreen {
          right: -50px;
          top: 130px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      right: 100px;
      top: -50px;
      width: 400px;

      &.secondScreen {
        right: -70px;
        top: 10px;
      }

      &.thirdScreen {
        right: 25px;
        top: 120px;
      }

      &.ipad {
        top: -20px;
        right: 25px;
        width: 500px;

        &.portrait {
          right: -70px;
          top: 0px;

          &.secondScreen {
            right: 70px;
            top: 90px;
          }
        }

        &.secondScreen {
          right: -100px;
          top: 110px;
        }
      }
    }

    // @include media-breakpoint-down(sm) {
    //   top: 50px;
    //   left: 70px;

    //   &.secondScreen {
    //     top: 108px;
    //     left: 239px;
    //   }

    //   &.thirdScreen {
    //     top: 215px;
    //     left: 147px;
    //   }

    //   &.ipad {
    //     right: 11px;
    //     top: 11px;
    //     width: 65%;

    //     &.secondScreen {
    //       top: 118px;
    //       left: 200px;
    //     }
    //   }
    // }

    @media (max-width: 800px) {
      top: -5%;

      &.secondScreen {
        top: 15%;
      }

      &.thirdScreen {
        top: 52%;
      }

      &.ipad {
        top: 10px;
        &.portrait {
          top: 40px;

          &.secondScreen {
            right: 70px;
            top: 130px;
          }
        }

        &.secondScreen {
          top: 130px;
        }
      }
    }
    @media (max-width: 600px) {
      top: -10%;
      right: 90px;
      width: 360px;

      &.thirdScreen {
        top: 43%;
      }
      &.secondScreen {
        top: 9%;
      }

      &.ipad {
        width: 400px;

        &.portrait {
          width: 360px;
          top: 46px;
          left: 170px;

          &.secondScreen {
            top: 140px;
            left: 60px;
          }
        }

        &.secondScreen {
          right: -70px;
        }
      }
    }
    @media (max-width: 500px) {
      width: 75%;
      top: -11%;
      left: -7%;
      object-fit: contain;

      &.secondScreen {
        top: 9%;
        left: 27%;
      }

      &.thirdScreen {
        top: 44%;
        left: 9%;
      }

      &.ipad {
        width: 77%;
        top: 49px;
        left: 5%;

        &.portrait {
          width: 77%;
          top: 25px;
          left: 73px;
          &.secondScreen {
            top: 100px;
            left: -10px;
          }
        }

        &.secondScreen {
          bottom: -10%;
          left: 19%;
        }
      }
    }
    @media (max-width: 400px) {
      &.secondScreen {
        top: 5%;
      }

      &.thirdScreen {
        top: 35%;
      }

      &.ipad {
        left: -5%;
        top: -4%;

        &.portrait {
          top: 0;
          left: 60px;
          &.secondScreen {
            top: 60px;
          }
        }

        &.secondScreen {
          top: 18%;
          left: 20%;
        }
      }
    }
  }

  .ipad {
    width: 700px;
    right: 130px;
    top: -30px;

    &.portrait {
      right: -90px;
      top: 0px;
      &.secondScreen {
        right: 125px;
        top: 95px;
      }
    }

    &.secondScreen {
      right: -75px;
      top: 145px;
    }
  }
}

.macbookWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img:not(.background) {
    position: absolute;
    animation: fadeIn 0.5s ease-out forwards;
    animation-delay: 0.5s;
  }

  .background {
    animation: comeIn1 0.5s ease-out forwards;
  }

  img {
    opacity: 0;
    transform: scale(0);
    width: 700px;

    @include media-breakpoint-down(md) {
      width: 600px;
    }
    @include media-breakpoint-down(sm) {
      width: 500px;
    }
    @media (max-width: 500px) {
      width: 300px;
    }
    @media (max-width: 300px) {
      width: 250px;
    }
  }
}

.renderWithScreenshot {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    opacity: 0;
    transform: scale(0);
    max-width: 60%;

    transition: opacity 0.5s ease, transform 0.5s ease;

    &.fadeIn {
      opacity: 1;
      transform: scale(1);
    }

    &.fadeOut {
      opacity: 0;
      transform: scale(0);
    }
  }

  .renderPlacement {
    right: 20%;
  }
  .screenshotPlacement {
    right: 2.5%;
    height: 100%;
    top: 0;
  }
}

@include media-breakpoint-down(lg) {
  .renderWithScreenshot {
    .renderPlacement {
      right: 22.5%;
      height: 60%;
      top: 10%;
    }
    .screenshotPlacement {
      right: 8.5%;
      top: 10%;
      height: 50%;
    }
  }
}

@include media-breakpoint-down(md) {
  .renderWithScreenshot {
    .renderPlacement {
      right: 25%;
      height: 45%;
      top: 2%;
    }
    .screenshotPlacement {
      right: 7.5%;
      top: 0;
      height: 40%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .renderWithScreenshot {
    .renderPlacement {
      right: 37.5%;
      height: 95%;
      top: -1.5%;
    }
    .screenshotPlacement {
      right: 13%;
      top: 0;
      height: 90%;
    }
  }
}

.detailImgWrapper {
  .renderWithScreenshot {
    .renderPlacement {
      right: 27%;
      height: 110%;
      top: 15%;
    }
    .screenshotPlacement {
      right: 10%;
      top: 20%;
      bottom: 0;
      height: 90%;
    }
  }

  .renderOnly {
    margin-top: 10%;

    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
    @include media-breakpoint-down(md) {
      margin-top: 5%;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
}

.renderOnly {
  img {
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    opacity: 0;
    transform: scale(0);
    max-width: 90%;
    object-fit: contain;

    transition: opacity 0.5s ease, transform 0.5s ease;

    &.fadeIn {
      opacity: 1;
      transform: scale(1);
    }

    &.fadeOut {
      opacity: 0;
      transform: scale(0);
    }
  }

  .renderPlacement {
    height: 95%;
    top: 5%;
    right: 6.66%;

    @include media-breakpoint-down(xl) {
      right: 7vw;
    }

    @include media-breakpoint-down(lg) {
      height: 100%;
      top: 5vw;
      width: 40vw;
      right: 12.5vw;
      object-position: top center;
    }

    @include media-breakpoint-down(md) {
      height: 100%;
      top: 5vw;
      width: 40vw;
      right: 11.5vw;
      object-position: top center;
    }

    @include media-breakpoint-down(sm) {
      height: 32.5%;
      top: unset;
      right: 0;
      left: 0;
      min-width: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.detailImgWrapper {
  .renderOnly {
    position: relative;
    width: 100%;
    height: 100%;

    .renderPlacement {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      right: 0;
    }
  }
}
