@import '../../styles/index.scss';

.scrollBar {
  opacity: 1;
  width: 4px;
  height: 100px;
  margin: 80px auto;
  background-color: $primary-dark;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  border-radius: 4px;

  .filler {
    background-color: $primary;
    width: 100%;
    display: block;
    // transition: height 0.1s ease;
  }

  &.dotted {
    background-color: unset;
    background-image: linear-gradient(
      0deg,
      $primary-dark,
      $primary-dark 75%,
      transparent 75%,
      transparent 100%
    );
    background-size: 4px 20px;
    border: none;
    background-repeat: repeat-y;

    .filler {
      background-color: unset;
      background-image: linear-gradient(
        0deg,
        $primary,
        $primary 75%,
        transparent 75%,
        transparent 100%
      );
      background-size: 4px 20px;
      border: none;
      background-repeat: repeat-y;
    }
  }
}
