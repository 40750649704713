@import '../../styles//index.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex: 1;
  min-height: 60vh;
  color: $txt;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;

  & > p {
    text-align: center !important;
  }

  a {
    color: lighten($primary, 20) !important;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
