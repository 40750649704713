@import '../../styles/index.scss';

.article {
  display: flex;
  flex: 1;
  margin: auto;
  flex-direction: column;

  .img {
    width: 80%;
    display: block;
    margin: auto;
    margin-top: 60px;

    @include media-breakpoint-down(md) {
      width: 90%;
    }
  }

  .title {
    opacity: 0;
    transform: scale(0);
    color: $txt;
    font-size: 40px;
    font-family: Nexa;
    margin-bottom: 50px;
    font-weight: normal;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  .text {
    opacity: 0;
    transform: scale(0);
    color: $txt;
    font-size: 16px;
    max-width: 950px;
    text-align: center;
    line-height: 24px;
    margin: auto;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    &.bigger {
      font-size: 1.66rem !important;
      line-height: 2rem !important;
    }
  }

  &.significant {
    .text {
      font-size: 1.33rem;
      line-height: 1.5em;
      margin-bottom: 6px;

      @include media-breakpoint-down(md) {
        line-height: 1.25em;
      }
    }
  }

  .title,
  .text,
  .img {
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &.animateIn {
    .title,
    .text,
    .img {
      opacity: 1;
      transform: scale(1);
    }
    .text {
      transition-delay: 0.3s;
    }
    .img {
      transition-delay: 0.6s;
    }
  }

  &.animateOut {
    .title,
    .text,
    .img {
      opacity: 0;
      transform: scale(0);
    }
    .title {
      transition-delay: 0.3s;
    }
    .text {
      transition-delay: 0.6s;
    }
  }
}
