@import '../../styles/index.scss';

.articles {
  opacity: 1;

  & > .title {
    opacity: 0;
    transform: scale(0);
    color: $txt;
    font-size: 40px;
    font-family: Nexa;
    margin-bottom: 50px;
    font-weight: normal;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  & > .text {
    opacity: 0;
    transform: scale(0);
    color: $txt;
    font-size: 16px;
    max-width: 950px;
    text-align: center;
    line-height: 24px;
    animation-delay: 0.3s;
    margin: 0 auto 50px;
    white-space: pre-wrap;
    display: block;
    flex: 1;
  }

  .article {
    opacity: 0;
    transform: scale(0);

    .header {
      display: flex;
      flex-direction: row;
      padding: 0 1.5em;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      &.hasImg {
        padding-left: 0;
      }

      @include media-breakpoint-down(md) {
        padding: 0 !important;

        &:not(.hasImg) {
          .headerContent {
            .title,
            .subtitle {
              text-align: center;
              justify-content: center;
            }
          }
        }
      }

      .img {
        width: 4.25em;
        height: 4.25em;
        border: 3.5px solid $dark;
        border-radius: 100%;
        display: flex;
        flex-shrink: 0;
        overflow: hidden;
        margin-right: 1em;
        transition: border-color 0.3s ease-in-out;

        @include media-breakpoint-down(sm) {
          width: 3em;
          height: 3em;
        }
      }
      .headerContent {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-up(lg) {
          flex: 1;
        }

        .title {
          margin: 0;
          font-size: 18px;
          font-family: Nexa;
          font-weight: bold;
          color: $txt;
          margin: 0.5em 0;

          display: inline-flex;
          flex: 1;
        }
        .subtitle {
          font-style: italic;
          color: $primary;
          font-size: 14px;
          font-family: Nexa;
          overflow-wrap: break-word;
          font-weight: bold !important;
          white-space: pre-wrap;
          line-height: 1.3em;
          color: $txt-dark;

          display: inline-flex;
          flex: 1;
        }
      }
    }

    .title,
    .subtitle,
    .text {
      text-align: left;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .main {
      margin-top: 1em;
      padding: 0 7.5%;
      margin-left: 4em;

      @include media-breakpoint-down(md) {
        padding: 0 5%;
        margin-left: 0;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 2.5%;
      }

      .text {
        font-size: 15px;
        color: $txt;
        font-family: Nexa;
        white-space: pre-wrap;
      }
    }
  }

  .swipeCard {
    display: flex;
    flex: 1;
    margin: auto;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      justify-content: center;

      &:not(:last-of-type) {
        margin-bottom: 25px;
      }
    }
  }

  & > .title,
  & > .text,
  .article {
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  &.animateFadeIn {
    & > .title,
    & > .text,
    .article,
    .dots {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.animateFadeOut {
    & > .title,
    & > .text,
    .article,
    .dots {
      opacity: 0;
      transform: scale(0);
    }
  }

  .button {
    margin: auto;
  }
}
