@import '../../styles/index.scss';

.e404Wrapper {
  width: 100%;

  .e404 {
    opacity: 1;
    display: flex;
    color: $txt;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    align-items: center;

    .banner {
      transition: all 1s ease-in-out;
      position: relative;
      flex: 1;
      width: 100%;
      flex-shrink: 0;
      min-height: calc(100vh - 10em);
      max-height: calc(100vh - 10em);

      .title {
        top: calc(40% + 200px);
        position: absolute;
        color: $txt;
        margin: 0;
        font-weight: 300;
        font-size: 30px;
        text-align: center;
        width: calc(100% - 30px);
        overflow-wrap: break-word;
        text-overflow: wrap;
        white-space: pre-wrap;
        display: flex;
        flex-direction: column;
        transition: all 1s ease-in-out;

        span {
          font-size: 40px;
          font-weight: bold;
        }

        @include media-breakpoint-down(lg) {
          top: calc(30% + 200px);
        }

        @include media-breakpoint-down(md) {
          top: calc(25% + 190px);
          font-size: 27.5px;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 17.5%;
          font-size: 22.5px;
          top: 40%;
        }
      }

      .btnWrapper {
        margin: auto;
        position: absolute;
        bottom: 0em;
        left: 0;
        width: calc(100% - 30px);
        justify-content: center;
        display: flex;
        margin: 0 15px;
      }
    }
  }
}
