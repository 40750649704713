@import '../../styles/index.scss';

.caseItem {
  margin: 50px auto;
  color: $txt;

  .dOnly {
    @include media-breakpoint-down(md) {
      display: none !important;
      margin: none !important;
    }
  }

  .hlink {
    position: absolute;
    left: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    &.reverse {
      flex-direction: row-reverse;
    }

    .textWrapper {
      width: 50%;
      padding: 0 15px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .imgWrapper {
      width: 50%;
      padding: 0 15px;

      video {
        border-radius: 16px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .textWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      .title,
      .subtitle,
      .text,
      .link {
        opacity: 0;
        transform: scale(0);
        -moz-opacity: 0;
        -moz-transform: scale(0);
        -webkit-opacity: 0;
        -webkit-transform: scale(0);

        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, color 0.3s ease-in-out;
      }

      .title {
        a {
          font-size: 40px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 40px;
          cursor: pointer;
          text-decoration: none !important;
          color: $txt;
          line-height: 1.3em;

          @include media-breakpoint-down(md) {
            font-size: 30px;
          }
        }
      }

      .subtitle {
        a {
          color: $primary;
          margin: 0;
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: bold !important;
          cursor: pointer;
          text-decoration: none !important;
          line-height: 1.3em;

          @include media-breakpoint-down(md) {
            font-size: 20px;
            margin-top: 10px;
          }
        }
      }

      .link {
        background-color: transparent;
        color: $txt;
        border: none;
        transition: color 0.3s ease-in-out;
        cursor: pointer;
        letter-spacing: 5px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 17px;
        margin: 10px auto 10px 0;
        outline: none !important;
        text-decoration: none !important;
        opacity: 0;
        transform: scale(0);

        @include media-breakpoint-down(md) {
          margin: 10px auto;
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            color: $primary;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .title,
        .subtitle,
        .text {
          display: inline-flex;
          justify-content: center;
          text-align: center;
        }
      }
    }

    .imgWrapper {
      display: flex;
      flex: 1;
      position: relative;
      justify-content: center;
      align-items: center;
      max-width: 100vw;
      overflow: hidden;

      opacity: 0;
      transform: scale(0);
      -moz-opacity: 0;
      -moz-transform: scale(0);
      -webkit-opacity: 0;
      -webkit-transform: scale(0);

      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

      @include media-breakpoint-down(md) {
        max-width: 75vw;
        max-height: 500px;
        margin: auto;

        .backdrop {
          height: 500px;
        }
      }

      @include media-breakpoint-down(sm) {
        max-width: 60vw;
        max-height: 350px;

        .backdrop {
          height: 350px;
        }
      }

      &.disableMax {
        max-width: 100vw;
        max-height: auto;
      }

      .backdrop {
        position: static;
        max-width: 100%;

        svg {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .img {
        position: absolute;
        z-index: 1;
        max-width: 100%;
        object-fit: contain;
        height: 100%;
        cursor: pointer;

        &.smallImg {
          object-fit: scale-down;
        }

        @include media-breakpoint-down(md) {
          max-width: 75%;
        }
      }

      .replayBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        outline: none !important;
        color: $txt;
        font-size: 14px;
        opacity: 1;
        cursor: pointer;
        flex: 1;
        text-align: center;
        align-items: center;
        align-content: center;
        justify-content: center;
        justify-items: center;
        margin: auto;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-top: 10px;

        transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;

        &.hidden {
          opacity: 0;
        }

        &:hover {
          color: $primary;
        }
      }
    }

    &.visible {
      .title,
      .subtitle,
      .text,
      .link,
      .imgWrapper {
        opacity: 1;
        transform: scale(1);
        -moz-opacity: 1;
        -moz-transform: scale(1);
        -webkit-opacity: 1;
        -webkit-transform: scale(1);
      }

      .title {
        transition-delay: 0.3s;
        -moz-transition-delay: 0.3s;
      }

      .subtitle {
        transition-delay: 0.6s;
        -moz-transition-delay: 0.6s;
      }

      .text {
        transition-delay: 0.9s;
        -moz-transition-delay: 0.9s;
      }

      .link {
        transition: opacity 0.5s ease-in-out 1.2s, -moz-opacity 0.5s ease-in-out 1.2s,
          -webkit-opacity 0.5s ease-in-out 1.2s, transform 0.5s ease-in-out 1.2s,
          -moz-transform 0.5s ease-in-out 1.2s, -webkit-transform 0.5s ease-in-out 1.2s,
          color 0.3s ease-in-out 0;
      }
    }

    &.hidden {
      .title,
      .subtitle,
      .text,
      .link,
      .imgWrapper {
        opacity: 0;
        transform: scale(0);
      }

      .imgWrapper {
        transition-delay: 1.2s;
        -moz-transition-delay: 1.2s;
      }

      .title {
        transition-delay: 0.9s;
        -moz-transition-delay: 0.9s;
      }

      .subtitle {
        transition-delay: 0.6s;
        -moz-transition-delay: 0.6s;
      }

      .text {
        transition-delay: 0.3s;
        -moz-transition-delay: 0.3s;
      }
    }
  }

  .detail {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    flex-direction: column;

    .title,
    .subtitle,
    .text,
    .link,
    .imgWrapper {
      opacity: 1 !important;
      transform: scale(1) !important;
      -moz-opacity: 1 !important;
      -moz-transform: scale(1) !important;
      -webkit-opacity: 1 !important;
      -webkit-transform: scale(1) !important;
      transition-delay: 0 !important;
      -moz-transition-delay: 0 !important;
    }

    .badgesLogo {
      max-width: 200px;
      margin: 0 auto;
      margin-bottom: 50px;
      display: block;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;
    }

    .imgContain {
      object-fit: contain;
    }

    .listWrapper {
      align-items: flex-start;

      @include media-breakpoint-down(sm) {
        align-items: center;
        margin-top: 50px;
      }

      & > div {
        @include media-breakpoint-down(sm) {
          text-align: center;
        }
      }
    }

    .listItem {
      background-color: $txt;
      color: $layout-bg;
      line-height: 2;

      box-decoration-break: clone;
      -webkit-box-direction-break: clone;
      -moz-box-direction-break: clone;

      padding-left: 0.25em;
      padding-right: 0.25em;

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .sectionTitle {
      color: $txt;
      font-size: 50px;
      font-weight: bold;
      transition-delay: 0.3s;
      line-height: 1.3em;
      max-width: 90vw;
      text-align: center;
      opacity: 0;
      transform: scale(0);
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

      &.visible {
        opacity: 1;
        transform: scale(1);
      }

      @include media-breakpoint-down(sm) {
        font-size: 40px;
      }
    }

    .content {
      padding-top: 0 !important;
      // flex-direction: column !important;
    }

    .landing {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 30px;
      text-align: center;
      min-height: calc(100vh - 125px);

      .title {
        color: $txt;
        font-size: 60px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 20px;
        font-weight: bold;
        line-height: 1.3em;

        @include media-breakpoint-down(md) {
          font-size: 55px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 40px;
        }
      }
      .subtitle {
        text-align: center;
        color: $primary;
        margin: 1.25em auto 1.15em;
        font-size: 19px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 1.35em;

        @include media-breakpoint-down(md) {
          white-space: pre-wrap;
        }
      }
      .text {
        color: $txt;
        font-size: 16px;

        &.head {
          margin: auto;
          margin-top: 15px;
          max-width: 800px;
        }
      }

      .imgWrapper {
        margin-top: auto;
        margin-bottom: auto;
      }

      .imgWrapper {
        max-width: 100%;
        min-height: 200px;

        &.small {
          max-height: 35vh;
        }

        @include media-breakpoint-down(sm) {
          max-height: calc(30vh + 2em);
        }
      }

      .imgWrapper {
        video,
        .backdrop,
        .img {
          max-width: 100%;
          max-height: calc(100% - 100px);
          margin-top: 0em;

          @include media-breakpoint-down(md) {
            max-height: calc(35vh - 40px);
          }
        }

        &.small {
          video,
          .backdrop,
          .img {
            max-height: calc(35vh - 40px);
          }
        }
      }
    }

    .backdrop {
      svg {
        max-height: calc(35vh);
      }
    }

    .button {
      margin-top: 50px;

      transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out, opacity 0.5s ease,
        transform 0.5s ease, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in;

      &.animateIn {
        opacity: 1;
        transform: scale(1);
      }

      &.animateOut {
        opacity: 0;
        transform: scale(0);
      }

      opacity: 0;
      transform: scale(0);
    }

    .imgWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0px auto;

      .scrollBar {
        height: 50px;

        &:first-child {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .img,
      .bannerTexts {
        object-fit: contain;
        object-position: center;
        width: 100%;
        animation-delay: 1.5s;

        border-radius: 2em;
        transform: scale(0.9);

        @include media-breakpoint-down(sm) {
          transform: scale(1);
        }
      }

      .bannerTexts {
        display: flex;
        justify-content: center;
        align-items: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 2em 0;
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }

        &.animateIn {
          opacity: 1;
          transform: scale(1);
        }

        &.animateOut {
          opacity: 0;
          transform: scale(0);
        }

        .bannerText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: calc(33.33% - 1em);
          margin: 0.5em;

          @include media-breakpoint-down(sm) {
            &:first-child {
              margin-top: 0;
            }
          }

          .title {
            color: $primary;
            text-overflow: wrap;
            white-space: pre-line;
            font-weight: bold;
            margin-bottom: 0.33em;
            font-size: 1.11em;
            line-height: 1.3em;
          }

          .list {
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;
          }

          @include media-breakpoint-down(md) {
            width: calc(50% - 1em);
          }

          @include media-breakpoint-down(sm) {
            width: calc(100% - 1em);
          }
        }
      }
    }

    .detailText {
      text-align: center;
      opacity: 0;
      transform: scale(0);
      transition: opacity 0.3s ease, transform 0.3s ease;

      &.animateIn {
        opacity: 1;
        transform: scale(1);
      }

      &.animateOut {
        opacity: 0;
        transform: scale(0);
      }

      &.bold {
        font-weight: bold;
        font-style: italic;
        font-size: 1.66em;
        line-height: 1.75em;
        margin: 175px 0;
        white-space: pre-line;

        @include media-breakpoint-down(md) {
          line-height: 1.5em;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.9em;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .articles {
      .article {
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.3s ease, transform 0.3s ease;

        &:not(:nth-last-child(2)),
        &:not(:last-child) {
          margin-bottom: 25px;
        }

        &.animateIn {
          opacity: 1;
          transform: scale(1);
        }

        &.animateOut {
          opacity: 0;
          transform: scale(0);
        }

        @include media-breakpoint-down(md) {
          &:not(:last-child) {
            margin-bottom: 50px;
          }
        }

        .title {
          font-size: 20px;
          color: $primary;
          text-align: center;
          margin-bottom: 15px;
          font-weight: bold;
          line-height: 1.3em;
        }
        .subtitle {
          color: $txt;
          text-align: center;
          line-height: 1.3em;
        }

        .text {
          text-align: center;
        }
      }
    }

    .badges {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;

      .badge {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        margin: 7.5px;
        flex: 1;

        .iconWrapper {
          background-image: url('../../assets/images/icons/hexagon.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: flex;
          justify-content: center;
          padding: 1em;
        }
        .badgeTitle {
          font-size: 0.9em;
          font-weight: bold;
          margin-top: 25px;
          text-align: center;
          white-space: pre-wrap;
          min-height: 34px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          align-content: center;
        }
      }
    }
  }

  .mobile {
    display: none !important;
    @include media-breakpoint-down(md) {
      display: flex !important;
    }
  }

  .desktop {
    display: flex !important;
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .mt100 {
    margin-top: 100px !important;
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .mb50 {
    margin-bottom: 50px !important;
  }

  .mb175 {
    margin-bottom: 175px !important;
  }

  .mt10 {
    margin-top: 10px;
  }

  .detailSBar {
    margin-top: 0;

    // @include media-breakpoint-down(md) {
    //   margin-top: 100px;
    // }
  }

  .text.link {
    color: $txt-dark !important;
    text-decoration: none !important;
    text-align: center !important;
  }

  .hIntrinsic {
    height: auto;
    height: intrinsic;
  }
}
.trustedBy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;

  transform: scale(0);
  -moz-opacity: 0;
  -moz-transform: scale(0);
  -webkit-opacity: 0;
  -webkit-transform: scale(0);

  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, color 0.3s ease-in-out;

  .partners {
    max-width: 120px;
    opacity: 0.5;
  }

  &.animateIn {
    opacity: 1;
    transform: scale(1);
  }

  &.animateOut {
    opacity: 0;
    transform: scale(0);
  }
}

.imgComponent {
  transform: scale(0.65);
}

.faqSection {
  opacity: 1;

  margin-top: 5em;
  @include media-breakpoint-down(sm) {
    margin-top: 3.5em;
  }

  svg {
    fill: $txt !important;
    opacity: 0.6;
  }

  a {
    color: $primary !important;
  }

  :global(.faq-row-wrapper) {
    background-color: $layout-bg;

    :global(.faq-title) {
      border-bottom: none;

      h2 {
        width: 100%;
        margin-bottom: 0.25em;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0.05em;
        }
      }

      &,
      * {
        color: $txt !important;
        font-size: 1.2em;

        @include media-breakpoint-down(sm) {
          font-size: 1.15em;
        }
      }
    }

    :global(.faq-body) {
      :global(.faq-row) {
        border-bottom-color: $primary-dark;

        :global(.row-title) {
          color: $txt;

          &[aria-expanded='true'] {
            font-weight: bold;
          }

          @include media-breakpoint-down(sm) {
            font-size: 1.05em;
          }
        }

        :global(.row-content) {
          :global(.row-content-text) {
            color: $txt;
            padding-bottom: 1.25em;

            @include media-breakpoint-down(sm) {
              font-size: 0.95em;
            }
          }
        }
      }
    }
  }
}
