@import '../../styles//index.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex: 1;
  min-height: 60vh;
  color: $txt;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;

  & > .title {
    margin-top: 100px;
    text-align: center;
  }

  .article {
    .title {
      margin-top: 25px;
      text-align: center;
    }
    .paragraph {
      text-align: center;

      .email {
        color: lighten($primary, 20);
        text-decoration: none !important;
        cursor: pointer;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
