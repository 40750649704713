@import '../../styles/index.scss';

@keyframes pulseLoader {
  from {
    transform: scale(0.5);
    border-color: $primary-dark;
  }
  to {
    transform: scale(1);
    border-color: $primary;
  }
}

.divider {
  border-bottom: 1px solid white;
  width: 100%;
}

.contactForm {
  .links {
    color: $txt;
    text-decoration: none;
    cursor: pointer;
    display: block;
    text-align: center;
    line-height: 2em;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: $primary;
    }
  }

  .mt50 {
    margin-top: 50px;
  }

  .mt25 {
    margin-top: 25px;
  }

  .mb50 {
    margin-bottom: 50px;
  }

  input {
    -webkit-appearance: none !important;
  }

  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  flex-direction: column;

  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);

  background-color: transparentize($color: $layout-bg, $amount: 0.5);

  transition: z-index 0.5s ease-in-out, opacity 0.5s ease-in-out;

  &.active {
    opacity: 1;
  }

  .closeText {
    color: $txt;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    align-content: flex-end;
    align-items: flex-end;
    font-size: 16px;
    margin-top: 15px;

    &:hover {
      color: $primary;
    }
  }

  .closeButton {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 5em;
    top: 5em;
    outline: none !important;
    cursor: pointer;

    i {
      font-size: 3em;
      color: $txt;
    }

    @include media-breakpoint-down(md) {
      position: static;
      align-self: flex-end;

      i {
        font-size: 2em;
        margin-right: 2em;
      }
    }
  }

  .title {
    color: $txt;
    font-size: 40px;
    font-weight: normal;
    margin-bottom: 40px;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .form {
    color: $txt;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    width: 100%;

    .wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      width: 100%;

      .multiCol {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        flex: 1;
        width: 100%;
        max-width: calc(849px + 2em);

        @include media-breakpoint-between(sm, md) {
          flex-direction: row !important;
          flex-wrap: wrap;
          max-width: calc(500px + 1em);
          justify-content: space-between;

          .paragraph {
            width: 250px !important;
            min-width: 250px !important;
            max-width: 250px !important;

            & > span {
              max-width: 100%;

              .input {
                max-width: 250px;
              }
            }

            &:nth-child(1) {
              margin-left: 0 !important;
              margin-right: 0.5em !important;
            }

            &:nth-child(2) {
              margin-left: 0.5em !important;
              margin-right: 0 !important;
            }

            &:nth-child(3) {
              margin-left: 0 !important;
              margin-right: 0 !important;
              max-width: 100% !important;
              min-width: 100% !important;
              width: 100% !important;

              & > span {
                .input {
                  max-width: 100%;
                }
              }
            }
          }
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        .paragraph {
          justify-content: flex-end;

          @include media-breakpoint-down(md) {
            margin: auto !important;
          }

          span {
            max-height: 70px;
          }
        }

        .paragraph:not(:last-child) {
          margin-right: 1em;
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          margin: auto;
          justify-content: center;
          align-content: center;

          .paragraph:not(:last-child) {
            margin-right: 0em;
          }

          .paragraph {
            margin: auto;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
        }
      }

      .paragraph {
        text-align: center;
        line-height: 30px;
        margin: 0;
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex: 1;
        width: 100%;
        max-width: 293px;

        &.double {
          max-width: calc(566px + 1em);

          span {
            width: 100%;
            max-width: calc(566px + 1em) !important;
          }

          @include media-breakpoint-down(sm) {
            max-width: 293px;
            margin: auto;
            justify-content: center;
            align-content: center;
            flex-direction: column;
          }
        }

        &.triple {
          max-width: calc(849px + 2em);

          span {
            width: 100%;
            max-width: calc(849px + 2em);
          }

          @include media-breakpoint-down(md) {
            max-width: 293px;
            margin: auto;
            justify-content: center;
            align-content: center;
            flex-direction: column;
          }
        }

        span {
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 293px;

          &.disMw {
            max-width: 100% !important;
            width: 100%;
          }
        }

        .label {
          transform: scale(0);
          opacity: 0;
          font-size: 13px !important;
          text-align: left;
          padding-left: 0.75em;
          margin-top: 0.5em;
          font-weight: bold;

          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

          &.active {
            transform: scale(1);
            opacity: 1;
          }
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        &.reverse {
          flex-direction: row-reverse;
        }

        @include media-breakpoint-between(sm, md) {
          font-size: 15px;
          max-width: calc(500px + 1em);
          min-width: calc(500px + 1em);
        }
      }
    }

    .input {
      border: none;
      border-color: transparent;
      outline-color: transparent;
      border-bottom: 1px solid $txt-darkest;
      background-color: transparent !important;
      color: $txt;
      padding-left: 0.75em;
      padding-right: 0.75em;
      font-size: 14px;
      font-family: NexaLight;
      outline: none !important;
      max-width: 293px;
      -webkit-appearance: none !important;
      border-radius: 0 !important;
      font-weight: bold;

      &.double {
        max-width: calc(566px + 1em);
      }
      &.triple {
        max-width: calc(849px + 2em);
      }

      &::placeholder {
        color: $txt;
      }

      &.error {
        border-color: $danger;
        color: $danger;
        -webkit-text-fill-color: $danger;
      }

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 8px;
      }
    }

    .tarea {
      width: 100%;
      height: 100px;
      max-width: 100% !important;
    }

    .button {
      margin-top: 50px;
      transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
        background-color 0.3s ease-in-out;

      &.disabled {
        opacity: 0.5;
        border-color: $txt !important;
        color: $txt !important;
        background-color: transparent !important;
        cursor: not-allowed !important;
        pointer-events: none;
      }

      @include media-breakpoint-down(md) {
        margin-top: 30px;
      }
    }
  }

  .clutchBannerWrapper {
    width: 187px;
    height: 50px;
    border: none;
    outline: none;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
    border: none;

    .banner {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      border: none;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .notice {
    text-align: center;
    font-size: 12px;
    max-width: 500px;
    padding: 10px;
    margin-bottom: 0;

    a {
      font-size: 13px;
      color: $primary;
      text-decoration: none;

      transition: 0.3s color ease-in-out;

      &:hover {
        color: $txt;
        text-decoration: underline;
      }
    }
  }

  .loader {
    width: 120px;
    height: 120px;
    border: 25px solid $primary;

    animation-iteration-count: infinite;
    animation-name: pulseLoader;
    animation-duration: 0.6s;
    animation-direction: alternate;
    box-sizing: border-box;
    border-radius: 100%;
  }
}
