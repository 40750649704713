@import '../../styles/index.scss';

.button {
  outline: none !important;
  border: 1px solid transparent;
  color: $txt !important;
  background-color: darken($primary, 3);
  font-family: Nexa;
  font-size: 16px;
  letter-spacing: 3px;
  width: 250px;
  line-height: 40px;
  max-width: 75vw;
  padding: 0;
  margin: 0.5em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 2px; //fixed spacings, probably font related issue
  -webkit-tap-highlight-color: transparent;
  overflow-wrap: break-word;
  display: block;
  text-align: center;
  border-radius: $bradius;
  text-decoration: none !important;

  transition: box-shadow 0.3s ease-in;

  &:hover,
  &.active {
    box-shadow: 1px 1px 30px lighten($layout-bg, 15);
  }

  &.disabled {
    opacity: 0.5;
    border-color: $txt !important;
    color: $txt !important;
    background-color: transparent !important;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}
