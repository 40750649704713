@import '../../styles/index.scss';

.container {
  .slide {
    padding: 0;
    width: 88px !important;
  }

  @include media-breakpoint-down(md) {
    padding: 0 calc(50% - 51px);

    .slide {
      width: auto;
    }
  }
}

.serviceSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    margin-top: 1em;
    margin-bottom: 0.5em;
    text-align: left;
    padding-left: 0.75em;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    flex-direction: row !important;
  }

  .services {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;

    @include media-breakpoint-down(xs) {
      max-width: 200px !important;
    }

    span.service {
      position: relative;
      display: flex;
      flex-grow: 0;
      flex-direction: column;
      border-radius: 8px;
      width: 85px !important;
      min-width: 85px !important;
      max-width: 85px !important;
      margin: 1.5px;
      padding: 2px;
      filter: grayscale(1);
      opacity: 1;
      transition: opacity 0.5s ease, filter 0.5s ease, border 0.5s ease, color 0.5s ease,
        background-color 0.5s ease;
      border: 2px solid transparent;
      height: 105px;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;

      span.close {
        opacity: 0;
        position: absolute;
        display: flex;
        right: -10px;
        top: -10px;
        width: 20px !important;
        max-width: 20px !important;
        height: 20px;
        font-size: 12px;
        border-radius: 100%;
        background-color: $primary;
        justify-content: center;
        align-items: center;
        transition: opacity 0.5s ease;

        i {
          color: $txt !important;
        }

        svg {
          path {
            fill: $txt !important;
          }
        }
      }

      .icon {
        display: block;
        height: 3em;
        object-fit: contain;
        margin: 2px auto;
      }

      .name {
        font-size: 12px;
        line-height: 1.5em;
        display: inline-flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        max-height: 40px;
        white-space: pre-line;
        margin-bottom: 4px;
      }

      &:hover:not(.active) {
        opacity: 1;
        filter: grayscale(0);
        border: 2px solid transparentize($color: $txt-darkest, $amount: 0.5);
      }

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }

      &.active {
        opacity: 1;
        filter: grayscale(0) !important;
        background-color: white;
        color: $layout-bg;
        max-width: 85px;

        span.close {
          opacity: 1;
        }

        path:first-of-type, path[fill="white"] {
          fill: $layout-bg;
        }
      }
    }
  }
}
