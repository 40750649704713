@import '../../styles/index.scss';

.formSuccess {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;

  .title {
    color: $txt;
    font-size: 40px;
    font-weight: normal;
    text-align: center;
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }
}

.text {
  color: $txt;
  max-width: 50%;
  text-align: center;
  font-size: 1.25em;
  margin-top: 0em;

  @include media-breakpoint-down(md) {
    max-width: 75%;
  }

  @include media-breakpoint-down(sm) {
    max-width: 90%;
    font-size: 1em;
  }
}

.divider {
  height: 6px;
  border-radius: 6px;
  background-color: $primary;
  width: 10vw;
}
