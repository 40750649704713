@import '../../styles/index.scss';

.arrowDown {
  height: 10em;
  width: 2.2em;
  top: 0;
}

.arrowDownLoaded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  // TODO:
  visibility: hidden;
}

.arrowsContainer {
  height: 10em;
  position: relative;
  margin: 80px auto;
}

.arrowCover {
  position: absolute;
  z-index: 1;
  left: 15px;
  width: 6px;
  background: $layout-bg;
}

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .step {
    justify-content: center;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    padding: 0 50px;
    margin: 0 50px;
    position: relative;

    .icon {
      width: 5em;
      height: 5em;
      margin-bottom: 2em;
      margin-top: -1em;
      opacity: 1;
    }

    .title,
    .text {
      text-align: center;
    }
  }
}
