@import '../../styles/index.scss';

.articlesTiles {
  display: flex;
  flex-direction: column;

  &.animated {
    &.visible {
      .header {
        .title {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: 0s;
        }
        .text {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: 0.3s;
        }
        .tabs {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: 0.6s;
        }
      }
      .main {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.5s ease, transform 0.5s ease;
        transition-delay: 0.6s;
      }
      .footer {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.5s ease, transform 0.5s ease;
        transition-delay: 0.9s;
      }
    }

    &.hidden {
      .header {
        .title {
          opacity: 0;
          transform: scale(0);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: 0.9s;
        }
        .text {
          opacity: 0;
          transform: scale(0);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: 0.6s;
        }
        .tabs {
          opacity: 0;
          transform: scale(0);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: 0.3s;
        }
      }
      .main {
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.5s ease, transform 0.5s ease;
        transition-delay: 0.3s;
      }
      .footer {
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.5s ease, transform 0.5s ease;
        transition-delay: 0s;
      }
    }
  }

  .header {
    margin-bottom: 25px;

    .title {
      color: $txt;
      font-size: 35px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 25px;
      font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: 30px;
      }

      // @include media-breakpoint-down(sm) {
      //   font-size: 40px;
      // }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }

    .text {
      text-align: center;
      max-width: 800px;
      margin: auto;
    }
  }

  .main {
    .tiles {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;

      .intersectionTitle {
        color: $txt;
        font-size: 40px;
        text-align: center;
        margin: 0px auto 25px;
        font-weight: bold;
        transition-delay: 0s;

        @include media-breakpoint-down(md) {
          font-size: 33px;
        }
      }

      .tileWrapper {
        width: 30%;
        margin: 0.75em;
        color: $txt !important;
        text-decoration: none !important;

        @include media-breakpoint-up(md) {
          min-width: 324px;
          max-width: 324px;
          flex-grow: 1;
          align-self: stretch;
        }

        .tile {
          background-color: $dark;
          padding: 1.25em;
          border-radius: $bradius;
          border: 1px solid transparent;
          transition: border 0.5s ease;
          height: 100%;

          &.clickable {
            cursor: pointer;

            &:hover {
              border: 1px solid $primary;
            }
          }

          .img {
            height: 2.75em;
            width: 2.75em;
            object-fit: contain;
          }

          .title {
            margin: 0.5em 0.25em 0.5em 0.25em;
            font-size: 18px;
            font-weight: bold;
            white-space: pre-line;
          }

          .text {
            font-size: 14px;
            margin: 0 0.25em 0.33em;

            &:last-child {
              margin-bottom: 0.5em;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        .tileWrapper {
          width: 100%;
          max-width: 444px;

          margin-top: 0.6em;
          margin-bottom: 0.6em;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin: calc(45px - 0.75em) auto 0px;

    .button {
      margin: 0px auto 10px;
    }
    .anchor {
      font-style: italic;
      color: $primary;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .mobile {
    @include media-breakpoint-up(lg) {
      display: none !important;
      margin: 0 !important;
      height: 0 !important;
      overflow: hidden;
    }

    .slide:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  .desktop {
    @include media-breakpoint-down(md) {
      display: none !important;
      margin: 0 !important;
      height: 0 !important;
      overflow: hidden;
      &.mt50 {
        margin-top: 0 !important;
      }
    }
  }

  .mt50 {
    margin-top: 50px !important;
  }
}
