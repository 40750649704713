@import '../../styles/index.scss';

.solution {
  color: $txt;
  padding-top: 30px;

  @include media-breakpoint-up(xl) {
    max-width: calc(100% - 15%);
    margin: 0 7.5%;
    padding-right: 0;
    padding-left: 0;
  }
}
.root {
  text-align: center;

  // .discoverButton {
  //   margin-top: 32px;
  // }

  .testimonialWrap {
    margin-top: 20px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in, transform 0.5s ease-out;

    .testimonialText {
      max-width: 500px;
      margin: 32px auto 16px;
    }

    &.animateIn {
      opacity: 1;
      transform: scale(1);
    }
    &.animateOut {
      opacity: 0;
      transform: scale(0);
    }

    .title {
      color: $txt;
      font-size: 40px;
      font-family: Nexa;
      margin-bottom: 50px;
      font-weight: normal;
      text-align: center;
    }

    .img {
      width: 7.5em;
      height: 7.5em;
      border: 5px solid $primary;
      border-radius: 100%;
      display: flex;
      flex-shrink: 0;
      overflow: hidden;
      transition: border-color 0.3s ease-in-out;
      margin: 0 auto;
    }

    .testimonial {
      display: flex;
      flex-direction: row;
      justify-content: center;

      &.hasImg {
        padding-left: 0;
      }

      @include media-breakpoint-down(md) {
        padding: 0 !important;

        &:not(.hasImg) {
          .testimonialContent {
            .title,
            .subtitle {
              text-align: center;
              justify-content: center;
            }
          }
        }
      }

      .testimonialContent {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .title {
          margin: 0;
          font-size: 18px;
          font-family: Nexa;
          font-weight: bold;
          color: $txt;
          margin-bottom: 4px;
          display: inline-flex;
          text-align: center;
        }
        .subtitle {
          font-style: italic;
          color: $primary;
          font-size: 14px;
          font-family: Nexa;
          overflow-wrap: break-word;
          font-weight: bold !important;
          white-space: pre-wrap;
          line-height: 1.3em;
          color: $txt-dark;
          margin: 0;
          text-align: center;

          display: inline-flex;
          flex: 1;
        }
      }
    }
  }
  .backButton {
    position: fixed;
    top: 80px;
    right: 7.5%;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    z-index: 10;
    padding: 1px 5px;
    border-radius: 4px;
    letter-spacing: 1px;
    transition: background-color 0.3s ease;

    &.useBackground {
      background-color: $primary-dark;
    }

    &:hover {
      text-decoration: none;
    }

    & > .icon {
      margin-left: 4px;
      width: 12px;
      height: 12px;
    }

    @include media-breakpoint-down(md) {
      right: calc(50% - 36.5px);
    }
  }

  .title {
    color: $txt;
    font-size: 60px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 1.3em;

    @include media-breakpoint-down(md) {
      font-size: 55px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 40px;
    }
  }
  .subtitle {
    text-align: center;
    color: $primary;
    margin: 1.25em auto 1.15em;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 1.35em;
  }
  .text {
    color: $txt;
    font-size: 16px;

    a {
      color: $primary;
    }

    &.head {
      margin: auto;
      margin-top: 15px;
      max-width: 800px;
    }
  }

  @media (min-width: 400px) {
    .discoverButton {
      margin-top: 60px;
    }
  }

  @include media-breakpoint-up(md) {
    .discoverButton {
      margin-top: 140px;
    }
  }

  @media (min-width: 800px) {
    .discoverButton {
      margin-top: 60px;
    }
  }

  @include media-breakpoint-up(lg) {
    .stepperScroll {
      margin-bottom: -30px;
      width: 2px;
    }
    .postStepperScroll {
      margin-top: -30px;
      width: 2px;
    }

    .discoverButton {
      margin-top: 140px;
    }
  }

  .steps {
    display: block;
    font-size: 0;
    margin-top: 25px;

    .step {
      font-size: 0;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;

      @include media-breakpoint-up(lg) {
        &.reverse {
          flex-direction: row-reverse;

          .txtWrapper {
            align-items: flex-end;

            .title,
            .subtitle,
            .text {
              text-align: right;
            }
          }
        }
      }

      .txtWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px 0 0;
        justify-content: center;
        opacity: 0;
        transform: scale(0);
        -moz-opacity: 0;
        -moz-transform: scale(0);
        -webkit-opacity: 0;
        -webkit-transform: scale(0);
        transition: opacity 0.5s ease, transform 0.5s ease, moz-opacity 0.5s ease,
          -moz-transform 0.5s ease, -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease;

        &.animateIn {
          transition-delay: 0 !important;

          opacity: 1;
          transform: scale(1);
          -moz-opacity: 1;
          -moz-transform: scale(1);
          -webkit-opacity: 1;
          -webkit-transform: scale(1);
        }

        &.animateOut {
          transition-delay: 0 !important;

          opacity: 0;
          transform: scale(0);
          -moz-opacity: 0;
          -moz-transform: scale(0);
          -webkit-opacity: 0;
          -webkit-transform: scale(0);
        }

        @include media-breakpoint-up(lg) {
          max-width: 50%;
        }

        .title,
        .subtitle,
        .text {
          @include media-breakpoint-up(lg) {
            text-align: left;
          }

          a {
            color: $primary
          }
        }

        .title {
          font-size: 35px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 20px;
          transition: color 0.3s ease-in-out;

          @include media-breakpoint-down(md) {
            font-size: 30px;
          }
        }

        .subtitle {
          color: $primary;
          margin: 0;
          margin-bottom: 15px;
          font-size: 22px;
          font-weight: bold;
          transition: color 0.3s ease-in-out;
        }

        .text {
          color: $txt;
          font-size: 16px;
        }

        @include media-breakpoint-up(lg) {
          & > .imgWrapper {
            display: none;
          }
        }
      }

      .imgWrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        min-height: 450px;

        &.empty {
          height: 450px;
        }

        img {
          max-width: 100%;
          height: 100%;
          max-height: 500px;
          object-fit: contain;
        }
      }

      .imgWrapper {
        margin-top: 20px;
        @include media-breakpoint-down(md) {
          margin-top: 5px;
          margin-bottom: 100px;
        }
      }

      // .border02 {
      //   height: 70px;
      //   background-image: linear-gradient(0deg, #000, #000 75%, 	 transparent 75%, transparent 100%);
      //   background-size: 1px 20px;
      //   border: none;
      //   background-repeat: repeat-y;
      // }

      .scrollBar {
        margin: -10px 40px;
        height: auto;
        background-size: 2px 20px;
        width: 2px;

        & > div {
          background-size: 2px 20px;
        }
      }

      .stepper {
        display: flex;
        width: 2px;
        position: relative;
        font-size: 20px;
        margin: 12px 2em 0 calc(2em - 2px);

        background-image: linear-gradient(
          0deg,
          $primary-dark,
          $primary-dark 75%,
          transparent 75%,
          transparent 100%
        );
        background-size: 2px 20px;
        border: none;
        background-repeat: repeat-y;

        .loader {
          width: 2px;
          display: flex;
          position: absolute;
          background-image: linear-gradient(
            0deg,
            $primary,
            $primary 75%,
            transparent 75%,
            transparent 100%
          );
          background-size: 2px 20px;
          border: none;
          background-repeat: repeat-y;
          height: 50%;

          transition: height 0.1s ease;
        }

        .circle {
          position: absolute;
          left: -8.5px;
          color: $primary-dark;

          &:nth-of-type(1) {
            top: -14px;
          }
          &:nth-of-type(2) {
            bottom: -20px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        .title,
        .subtitle,
        .text {
          text-align: center;
          justify-content: center;
        }

        & > .imgWrapper,
        .stepper,
        .scrollBar {
          display: none;
        }
      }
    }
  }
}
.results {
  opacity: 0;
  transform: scale(0);
  -moz-opacity: 0;
  -moz-transform: scale(0);
  -webkit-opacity: 0;
  -webkit-transform: scale(0);
  transition: opacity 0.5s ease, transform 0.5s ease, moz-opacity 0.5s ease,
    -moz-transform 0.5s ease, -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease;

  .title {
    color: $txt;
    font-size: 40px;
    font-family: Nexa;
    margin-bottom: 30px;
    font-weight: normal;
    text-align: center;
  }

  .counters {
    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 50px;

    transition: opacity 0.5s ease, transform 0.5s ease, moz-opacity 0.5s ease,
      -moz-transform 0.5s ease, -webkit-opacity 0.5s ease, -webkit-transform 0.5s ease;
    &.text:not(.sAnimated) {
      transition-delay: 0.8s;
    }
    transition-delay: 0.3s;
    margin-bottom: 50px;

    &.animateIn {
      transition-delay: 0 !important;

      opacity: 1;
      transform: scale(1);
      -moz-opacity: 1;
      -moz-transform: scale(1);
      -webkit-opacity: 1;
      -webkit-transform: scale(1);
    }

    &.animateOut {
      transition-delay: 0 !important;

      opacity: 0;
      transform: scale(0);
      -moz-opacity: 0;
      -moz-transform: scale(0);
      -webkit-opacity: 0;
      -webkit-transform: scale(0);
    }

    @include media-breakpoint-down(md) {
      width: 380px;
      max-width: 90vw;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .counter {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      width: 190px;
      margin: 0 5px;
      position: relative;

      @include media-breakpoint-down(sm) {
        width: calc(50% - 10px);
        margin: 20px 5px;
        box-sizing: border-box;
      }

      @include media-breakpoint-down(md) {
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 25px;
        }
      }

      .title {
        color: $txt;
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        font-family: NexaBold;

        .infoIcon {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 16px;

          & + .infoPopover {
            position: absolute;
            top: 0px;
            right: 0px;
            border-radius: $border-radius-lg;
            border-top-right-radius: 0;
            font-size: 12px;
            line-height: 15px;
            font-family: Nexa;
            background-color: $primary-dark;
            width: 200px;
            padding: 8px;
            text-align: left;
            white-space: pre-wrap;
            transition: transform 0.3s ease, opacity 0.3s ease;
          }

          &:not(:hover) + .infoPopover {
            pointer-events: none;
            opacity: 0;
            transform: scale(0);
          }

          &:hover + .infoPopover,
          & + .infoPopover:hover {
            pointer-events: all;
            opacity: 1;
            transform: scale(1);
          }
        }

        &.small {
          font-size: 32px;
          margin-bottom: 17px;
        }

        @include media-breakpoint-down(md) {
          font-size: 45px;
        }
      }
      .subtitle {
        white-space: pre-wrap;
        color: $txt;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        margin: 0 auto;
      }
    }
  }

  &.visible {
    transition-delay: 0 !important;

    opacity: 1;
    transform: scale(1);
    -moz-opacity: 1;
    -moz-transform: scale(1);
    -webkit-opacity: 1;
    -webkit-transform: scale(1);
  }

  &.hidden {
    transition-delay: 0 !important;

    opacity: 0;
    transform: scale(0);
    -moz-opacity: 0;
    -moz-transform: scale(0);
    -webkit-opacity: 0;
    -webkit-transform: scale(0);
  }

  .infoBox {
    border: 1px solid #42464b;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    font-size: 12px;
    text-align: left;
    line-height: 16px;
    color: white;
    font-family: Nexa;
    font-weight: bold;
    min-width: 125px;
    margin: 16px 8px 0;

    & > div:last-child {
      font-size: 18px;
      margin-top: 12px;
      line-height: 16px;
    }
  }

  .companyLogo img {
    max-width: 200px;
    max-height: 120px;
    margin-top: -20px;
    margin-bottom: -20px;
    opacity: 0.5;
  }
}
